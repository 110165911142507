import { css } from '@emotion/react'
import { isLink } from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'
import { renderNodeRule } from 'react-datocms/structured-text'

import { DatoGatsbyImage } from '@/components/DatoGatsbyImage'
import { DatoStructuredText } from '@/components/DatoStructuredText'
import { bodyGrid } from '@/features/page-sections'
import { absoluteFill, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.LeaderArticleFragment | null
  layout?: 'LIGHTBOX' | 'PAGE' | 'ON_PAGE'
}

export const LeaderArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const Heading = layout === 'ON_PAGE' ? 'h2' : 'h1'
  const Subheading = layout === 'ON_PAGE' ? 'h3' : 'h2'
  const styles = {
    article: css`
      ${bodyGrid}
      background-color: #fff;
      ${layout === 'LIGHTBOX' &&
      css`
        padding: var(--row-36) var(--gtr-36) var(--row-54);
      `}
      ${layout === 'PAGE' &&
      css`
        padding: var(--row-72) var(--margin) var(--row-108);
        --border-width: 1.5rem;
        --grid-w: calc(100vw - 2 * var(--border-width));
        border: var(--border-width) solid ${colors.red};
        border-top-width: 0;
        ${mq().s} {
          --border-width: 0.75rem;
        }
      `} 
      ${layout === 'ON_PAGE' &&
      css`
        padding: var(--row-36) var(--margin) var(--row-72);
      `}
      ${mq().ms} {
        grid-template-columns: auto 1fr;
        grid-column-gap: var(--margin);
      }
    `,
    image: css`
      width: 100%;
      margin-left: calc(var(--margin) * -0.25);
      justify-self: center;
      align-self: flex-start;
      grid-row: 1 / 3;
      box-sizing: border-box;
      position: relative;
      [data-gatsby-image-wrapper] img {
        border-radius: 50%;
      }
      &:after {
        content: '';
        ${absoluteFill}
        border-radius: 50%;
        border: 1px solid #00000011;
        box-sizing: border-box;
      }
      ${layout === 'LIGHTBOX' &&
      css`
        width: 100%;
      `}
      ${layout === 'ON_PAGE' &&
      css`
        max-width: calc(var(--fs-144) * 2.5);
      `}
      ${mq().ms} {
        grid-row: auto;
        max-width: calc(var(--fs-144) * 1.75);
      }
    `,
    headingGroup: css`
      &:after {
        content: '';
        display: block;
        font-size: var(--fs-60);
        width: 1em;
        height: 2px;
        background: ${colors.red};
        margin: 0.5em 0 0.25em;
      }
      ${mq().ms} {
        align-self: center;
        margin-bottom: 0;
      }
    `,
    heading: css`
      font-size: var(--fs-60);
      line-height: 1.125;
      margin: 0.5em 0 0;
      ${mq().ms} {
        align-self: center;
        margin-top: 0.25em;
      }
      ${mq().s} {
        font-size: var(--fs-48);
      }
    `,
    subheading: css`
      font-family: var(--ff-body);
      font-size: var(--fs-18);
      /* text-transform: uppercase; */
      color: ${colors.gray45};
      margin: 1em 0;
      font-weight: 300;
      ${mq().ms} {
        margin: 0.5em 0;
      }
    `,
    biography: css`
      line-height: 1.75;
      max-width: 90ch;
      a {
        color: ${colors.red};
        font-weight: 450;
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
      ${mq().ms} {
        grid-column: 1 / -1;
        margin-top: 1.5em;
      }
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <div css={styles.image}>
        <DatoGatsbyImage
          image={data?.headshot?.gatsbyImageData}
          alt={data?.headshot?.alt || data?.name || ''}
        />
      </div>
      <div css={styles.headingGroup}>
        <Heading css={styles.heading}>{data?.name}</Heading>
        <Subheading css={styles.subheading}>{data?.title}</Subheading>
      </div>
      <div css={styles.biography}>
        <DatoStructuredText
          data={data?.biography}
          customNodeRules={[
            renderNodeRule(isLink, ({ node, children, key }) => (
              <a
                key={key}
                href={node.url}
                target="_blank"
                rel="noreferrer"
                {...node.meta}
              >
                {children}
              </a>
            )),
          ]}
        />
      </div>
    </article>
  )
}

export const LeaderArticleFragment = graphql`
  fragment LeaderArticle on DatoCmsLeader {
    __typename
    id: originalId
    name
    title
    headshot {
      gatsbyImageData(
        imgixParams: {
          fit: "crop"
          crop: "focalpoint"
          ar: "1:1"
          q: 50
        }
        width: 360
        sizes: "(max-width: 540px) 240px, (max-width: 720px) 360px, 33vw"
      )
      alt
    }
    education {
      location
      degree
    }
    biography {
      value
    }
    seoMetaTags {
      tags
    }
    slug
  }
`
